<script>
import consolesService from '@/services/consoles.service'
import generationsService from '@/services/generations.service'
import brandsService from '@/services/brands.service'
import mediaService from '@/services/media.service'
import accessoriesService from '@/services/accessories.service'
import bitsService from '@/services/bits.service'
import colorsService from '@/services/colors.service'
import palettesService from '@/services/palettes.service'
import libraryService from '@/services/library.service'

import Console from '@/data/models/Console'
import EditLayout from '@/layout/EditLayout'
import indexing from '@/data/enums/seoIndexing.enum'
import Modal from '@/components/Modal'
import LibraryList from '@/components/LibraryList'

import Draggable from 'vuedraggable'

export default {
  name: 'SingleConsole',
  components: {
    EditLayout,
    Modal,
    LibraryList,
    Draggable
  },
  beforeRouteLeave(to, from, next) {
    if(this.hasChanges) {
      this.$notify({
        message: this.$t('unsavedChanges'),
        icon: "far fa-bell",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      })
    }else {
      next()
    }
  },
  data() {
    return {
      item: new Console,
      categories: [],
      generations: [],
      brands: [],
      media: [],
      consoles: [],
      accessories: [],
      bits: [],
      colors: [],
      palettes: [],
      photo: {},
      pdfList: [],
      gallery: [],
      tempGallery: [],
      isGalleryModalOpened: false,
      indexing,
      isLoaded: false,
      hasChanges: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.hasChanges = this.isLoaded
        this.hasChanges && this._preventWindowClose()
        this.isLoaded = true
      }
    }
  },
	mounted() {
    !this.isNew && this.id && this._get()
    this._getCategories()
    this._getGenerations()
    this._getBrands()
    this._getMedia()
    this._getConsoles()
    this._getAccessories()
    this._getBits()
    this._getColors()
    this._getPalettes()
    this._getAllPdf()
	},
  methods: {
    _preventWindowClose() {
      window.onbeforeunload = this.confirmMessage
    },
    confirmMessage() {
      return this.$t('unsavedChangesQuestion')
    },
    _get() {
      consolesService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
          res.data.library_id && this._getPhoto({ id: res.data.library_id })
          this.gallery = res.data.gallery || []
        })
    },
    _getCategories() {
      consolesService.getCategories()
        .then(res => {
          this.categories = res.data
        })
    },
    _getGenerations() {
      generationsService.getAll()
        .then(res => {
          this.generations = res.data
        })
    },
    _getBrands() {
      brandsService.getAll({})
        .then(res => {
          this.brands = res.data
        })
    },
    _getMedia() {
      mediaService.getAll()
        .then(res => {
          this.media = res.data
        })
    },
    _getConsoles() {
      consolesService.getAll({})
        .then(res => {
          this.consoles = res.data
        })
    },
    _getAccessories() {
      accessoriesService.getAll({})
        .then(res => {
          this.accessories = res.data
        })
    },
    _getBits() {
      bitsService.getAll()
        .then(res => {
          this.bits = res.data
        })
    },
    _getColors() {
      colorsService.getAll()
        .then(res => {
          this.colors = res.data
        })
    },
    _getPalettes() {
      palettesService.getAll()
        .then(res => {
          this.palettes = res.data
        })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    _getAllPdf() {
      libraryService.getAll({ type: 'pdf' })
        .then(res => {
          this.pdfList = res.data
        })
    },
    create() {
      consolesService.create({ data: this.item })
      .then(res => {
        this.hasChanges = false;
        this.$notify({
          message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('console') }),
          icon: "far fa-bell",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
        this.$router.push({ name: 'consoles' })
      });
    },
    edit() {
      consolesService.edit({ data: this.item })
        .then(res => {
          this.hasChanges = false;
          window.onbeforeunload = null;
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('console') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        });
    },
    updateSelectedBrands(newVal) {
      this.item.brand = newVal
    },
    updateSelectedColors(newVal) {
      this.item.colors = newVal
    },
    updateSelectedMedia(newVal) {
      this.item.media = newVal
    },
    updateSelectedAccessories(newVal) {
      this.item.related_accessories = newVal
    },
    updateGallery(items) {
			this.tempGallery = items
		},
    confirmNewGallery() {
			if(this.tempGallery && this.tempGallery.length) {
        this.gallery = this.tempGallery
        this.tempGallery = []
        this.updateGalleryModel()
      }
			this.isGalleryModalOpened = false
    },
    updateGalleryModel() {
			this.item.gallery = this.gallery.map((item, i) => {
        return { id: item.id, position: i + 1 }
      })
    },
  }
}
</script>

<template>
  <EditLayout
    supports-photo
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="subtitle">
      <div class="row">
        <BaseInput
          v-model="item.nickname"
          class="subtitle col-sm-6"
          :label="$t('nickname')"
          :placeholder="$t('enterNickname')"
        />
        <div class="col-sm-6">
          <label>{{ $tc('category') }}</label>
          <select
            v-model="item.category_id"
            class="form-control"
          >
            <option
              v-for="category in categories"
              :key="category.id+'category'"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div slot="middle">
      <label>{{ $t('gallery') }}</label>
      <div class="gallery">
        <div v-if="!gallery.length">
          <BaseIcon
            v-for="index in 6"
            :key="index"
            icons-group="fas"
            icon="fa-image"
            size="xxl"
            class="placeholderIcons"
          />
        </div>
        <Draggable
          v-model="gallery"
          class="galleryInner"
          @change="updateGalleryModel"
        >
          <div
            v-for="galleryItem in gallery"
            :key="'gallery' + galleryItem.id"
            class="galleryItem"
          >
            <BaseImage
              :source="galleryItem"
              size="thumb"
            />
          </div>
        </Draggable>
        <BaseButton
          type="green"
          gradient
          @click="isGalleryModalOpened = true"
        >
          {{ $t('updateGallery') }}
        </BaseButton>
      </div>
      <Modal
        :show.sync="isGalleryModalOpened"
        :centered="true"
        :show-close="true"
      >
        <LibraryList
          v-if="isGalleryModalOpened"
          :limit="40"
          select-items="multiple"
          class="library"
          :preselected-items="gallery"
          fixed-type="photos"
          grid-item-size="100"
          @updateSelected="updateGallery"
        />
        <div class="libraryButton text-right">
          <BaseButton
            type="green"
            gradient
            @click="confirmNewGallery"
          >
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </Modal>
      <div class="dates">
        <label>{{ $tc('date', 2) }}</label>
        <div class="row">
          <BaseInput
            v-model="item.release_date_worldwide"
            :label="`${$t('releaseDate')} ${$t('worldwide')}`"
            class="col-sm-6"
            type="date"
          />
          <BaseInput
            v-model="item.discontinue_date_worldwide"
            :label="`${$t('discontinuedDate')} ${$t('worldwide')}`"
            class="col-sm-6"
            type="date"
          />
        </div>
        <div class="row">
          <BaseInput
            v-model="item.release_date_australia"
            :label="`${$t('releaseDate')} Australia`"
            class="col-sm-6"
            type="date"
          />
          <BaseInput
            v-model="item.discontinue_date_australia"
            :label="`${$t('discontinuedDate')} Australia`"
            class="col-sm-6"
            type="date"
          />
        </div>
        <div class="row">
          <BaseInput
            v-model="item.release_date_eu"
            :label="`${$t('releaseDate')} EU`"
            class="col-sm-6"
            type="date"
          />
          <BaseInput
            v-model="item.discontinue_date_eu"
            :label="`${$t('discontinuedDate')} EU`"
            class="col-sm-6"
            type="date"
          />
        </div>
        <div class="row">
          <BaseInput
            v-model="item.release_date_jp"
            :label="`${$t('releaseDate')} Japan`"
            class="col-sm-6"
            type="date"
          />
          <BaseInput
            v-model="item.discontinue_date_jp"
            :label="`${$t('discontinuedDate')} Japan`"
            class="col-sm-6"
            type="date"
          />
        </div>
        <div class="row">
          <BaseInput
            v-model="item.release_date_namerica"
            :label="`${$t('releaseDate')} N. America`"
            class="col-sm-6"
            type="date"
          />
          <BaseInput
            v-model="item.discontinue_date_namerica"
            :label="`${$t('discontinuedDate')} N. America`"
            class="col-sm-6"
            type="date"
          />
        </div>
        <div class="row">
          <BaseInput
            v-model="item.release_date_usa"
            :label="`${$t('releaseDate')} USA`"
            class="col-sm-6"
            type="date"
          />
          <BaseInput
            v-model="item.discontinue_date_usa"
            :label="`${$t('discontinuedDate')} USA`"
            class="col-sm-6"
            type="date"
          />
        </div>
      </div>
    </div>
    <!-- + SIDEBAR MIDDLE + -->
    <div slot="sidebar-middle">
      <div>
        <label>{{ $t('predecessor') }}</label>
        <select
          v-model="item.previous_console"
          class="form-control"
        >
          <option :value="null">
            {{ $t('selectAConsole') }}
          </option>
          <option
            v-for="consolo in consoles"
            :key="consolo.id+'consolo-previous'"
            :value="consolo.id"
          >
            {{ consolo.name }}
          </option>
        </select>
      </div>
      <div>
        <label>{{ $t('successor') }}</label>
        <select
          v-model="item.next_console"
          class="form-control"
        >
          <option :value="null">
            {{ $t('selectAConsole') }}
          </option>
          <option
            v-for="consolo in consoles"
            :key="consolo.id+'consolo-next'"
            :value="consolo.id"
          >
            {{ consolo.name }}
          </option>
        </select>
      </div>
      <div>
        <BaseMultiSelect
          :label="$tc('brand', 2)"
          :items="brands"
          :pre-selected-items="item.brand"
          @updateSelected="updateSelectedBrands"
        />
      </div>
      <div>
        <label>{{ $tc('generation') }}</label>
        <select
          v-model="item.generation_id"
          class="form-control"
        >
          <option
            v-for="generation in generations"
            :key="generation.id+'generation'"
            :value="generation.id"
          >
            {{ generation.name }} {{ $tc('generation') }}
          </option>
        </select>
      </div>
      <div>
        <BaseMultiSelect
          :label="$tc('medium', 2)"
          :items="media"
          :pre-selected-items="item.media"
          @updateSelected="updateSelectedMedia"
        />
      </div>
      <div>
        <BaseMultiSelect
          :label="$tc('accessory', 2)"
          :items="accessories"
          :pre-selected-items="item.related_accessories"
          @updateSelected="updateSelectedAccessories"
        />
      </div>
      <div>
        <label>{{ $tc('bit', 2) }}</label>
        <select
          v-model="item.bit_id"
          class="form-control"
        >
          <option :value="null">
            {{ $t('selectConsoleBits') }}
          </option>
          <option
            v-for="bit in bits"
            :key="bit.id+'bits'"
            :value="bit.id"
          >
            {{ bit.name }}
          </option>
        </select>
      </div>
      <div>
        <BaseMultiSelect
          :label="$tc('color', 2)"
          :items="colors"
          :pre-selected-items="item.colors"
          @updateSelected="updateSelectedColors"
        />
      </div>
      <div>
        <label>{{ $t('colorPalette') }}</label>
        <select
          v-model="item.palette_id"
          class="form-control"
        >
          <option :value="null">
            {{ $t('selectColorPalette') }}
          </option>
          <option
            v-for="palette in palettes"
            :key="palette.id+'palettes'"
            :value="palette.id"
          >
            {{ palette.name }}
          </option>
        </select>
      </div>
      <div>
        <label>PDF</label>
        <select
          v-model="item.pdf_id"
          class="form-control"
        >
          <option :value="null">
            {{ $t('select') }} PDF
          </option>
          <option
            v-for="pdf in pdfList"
            :key="pdf.id+'pdf'"
            :value="pdf.id"
          >
            {{ pdf.photo }}
          </option>
        </select>
      </div>
      <BaseInput
        v-model="item.sizes"
        :label="$tc('size', 2)"
        :placeholder="$tc('size', 2)"
      />
      <div class="row">
        <BaseInput
          v-model="item.games_number"
          class="col-sm-6"
          :label="$t('totalGames')"
          :placeholder="$t('totalGames')"
        />
        <BaseInput
          v-model="item.initial_price"
          class="col-sm-6"
          :label="$t('initialPrice')"
          :placeholder="$t('initialPrice')"
        />
      </div>
      <BaseInput
        v-model="item.units_sold"
        :label="$t('unitsSold')"
        type="number"
        :placeholder="$t('unitsSold')"
      />
      <div class="row">
        <BaseInput
          class="col-sm-4 col-6"
          :label="$t('educational')"
          is-slider-checkbox
        >
          <input
            v-model="item.educational"
            class="form-control"
            type="checkbox"
            true-value="1"
            false-value="0"
          >
        </BaseInput>
        <BaseInput
          class="col-sm-4 col-6"
          :label="$t('retro')"
          is-slider-checkbox
        >
          <input
            v-model="item.retro"
            class="form-control"
            type="checkbox"
            true-value="1"
            false-value="0"
          >
        </BaseInput>
        <BaseInput
          class="col-sm-4 col-6"
          :label="$t('unreleased')"
          is-slider-checkbox
        >
          <input
            v-model="item.unreleased"
            class="form-control"
            type="checkbox"
            true-value="1"
            false-value="0"
          >
        </BaseInput>
      </div>
    </div>
    <!-- SIDEBAR BOTTOM -->
    <div slot="sidebar-bottom">
      <div class="metaIndexling form-group">
        <label>{{ $t('metaIndexing') }}</label>
        <select
          v-model="item.seo_indexing"
          class="form-control"
        >
          <option :value="null">
            {{ $t('indexing') }}
          </option>
          <option
            v-for="index in indexing"
            :key="index"
            :value="index"
          >
            {{ index }}
          </option>
        </select>
      </div>
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.gallery
  &Inner
    display: flex
    margin-bottom: $base-spacing
  &Item
    position: relative
  &Item~&Item
    margin-left: $base-spacing
.library
  max-height: 85vh
  overflow: auto
  &Button
    padding: $base-spacing
.dates
  margin-top: $base-spacing
.placeholderIcons~.placeholderIcons
  margin-left: $base-spacing
</style>

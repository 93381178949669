/* eslint-disable camelcase */
export default class Console {
  alias;
  bit_id;
  brand;
  category_id;
  description;
  description_socials;
  discontinue_date_australia;
  discontinue_date_eu;
  discontinue_date_final;
  discontinue_date_jp;
  discontinue_date_namerica;
  discontinue_date_usa;
  discontinue_date_worldwide;
  educational;
  gallery;
  games_number;
  generation_id;
  id;
  initial_price;
  library_id;
  media;
  name;
  next_console;
  nickname;
  palette_id;
  pdf_id;
  previous_console;
  published;
  related_accessories;
  release_date_australia;
  release_date_eu;
  release_date_final;
  release_date_jp;
  release_date_namerica;
  release_date_usa;
  release_date_worldwide;
  retro;
  seo_indexing;
  seo_title;
  sizes;
  type;
  units_sold;
  unreleased;

  constructor() {
    this.alias = '';
    this.bit_id = null;
    this.brand = [];
    this.category_id = null;
    this.description = '';
    this.description_socials = '';
    this.discontinue_date_australia = '';
    this.discontinue_date_eu = '';
    this.discontinue_date_jp = '';
    this.discontinue_date_namerica = '';
    this.discontinue_date_usa = '';
    this.discontinue_date_worldwide = '';
    this.educational = "0";
    this.gallery = [];
    this.games_number = null;
    this.generation_id = null;
    this.id = null;
    this.initial_price = null;
    this.library_id = null;
    this.media = [];
    this.name = '';
    this.next_console = null;
    this.nickname = '';
    this.palette_id = null;
    this.pdf_id = null;
    this.previous_console = null;
    this.published = 0;
    this.related_accessories = [];
    this.release_date_australia = '';
    this.release_date_eu = '';
    this.release_date_jp = '';
    this.release_date_namerica = '';
    this.release_date_usa = '';
    this.release_date_worldwide = '';
    this.retro = "0";
    this.seo_indexing = null;
    this.seo_title = '';
    this.sizes = null;
    this.type = 'console';
    this.units_sold = null;
    this.unreleased = "0";
  }
}

import { get, post, put, remove } from '@/services/api.service';

const API_URL = '/accessories';
const CATEGORIES_URL = '/categories';

class AccessoriesService {
  getAll({ category = null, brand = null, limit = null, offset = null, keyword = null }){
    const catParam = category ? `&category=${category}` : ''
    const brandParam = brand ? `&brand=${brand}` : ''
    const limitParam = limit ? `&limit=${limit}` : ''
    const offsetParam = offset ? `&offset=${offset - 1}` : ''
    const searchParam = keyword ? `&search=${keyword}` : ''
    const parameters = `?${catParam}${brandParam}${limitParam}${offsetParam}${searchParam}&extended=1`
    return get(`${API_URL}${parameters}`)
      .then(res => res)
  }
  getById({ id }) {
    return get(`${API_URL}/${id}?extended=1`)
      .then(res => res)
  }
  create({ data }) {
    return post(`${API_URL}`, data)
    .then(res => res)
  }
  edit({ data }) {
    return put(`${API_URL}/${data.id}`, data)
    .then(res => res)
  }
  remove({ id }) {
    return remove(`${API_URL}/${id}`)
      .then(res => res)
  }

  // CATEGORIES
  getCategories() {
    return get(`${CATEGORIES_URL}?type=accessories&extended=1`)
      .then(res => res)
  }
  getCategoryById({ id }) {
    return get(`${CATEGORIES_URL}/${id}?extended=1`)
  }
  createCategory({ data }) {
    return post(`${CATEGORIES_URL}`, data)
      .then(res => res)
  }
  editCategory({ data }) {
    return put(`${CATEGORIES_URL}/${data.id}`, data)
      .then(res => res)
  }
  removeCategory({ id }) {
    return remove(`${CATEGORIES_URL}/${id}`)
      .then(res => res)
  }
}

export default new AccessoriesService();
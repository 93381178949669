import { get, post, put, remove } from '@/services/api.service';

const API_URL = '/colors';

class ColorsService {
  getAll() {
    return get(`${API_URL}?extended=1`)
      .then(res => res);
  }
  getById({ id }) {
    return get(`${API_URL}/${id}?extended=1`)
    .then(res => res);
  }
  create({ data }) {
    return post(`${API_URL}`, data)
      .then(res => res);
  }
  edit({ data }) {
    return put(`${API_URL}/${data.id}`, data)
      .then(res => res);
  }
  remove({ id }) {
    return remove(`${API_URL}/${id}`)
      .then(res => res);
  }
}

export default new ColorsService();